<template>
    <b-container>
        <div class="d-lg-none topbut">
            <div class="fhbtn">
                <a href="\">返回</a>
            </div>
            <p>400电话办理查号中心</p>
        </div>
        <b-navbar toggleable="lg" type="dark" class="d-none d-lg-block">
            <b-navbar-brand href="/" target="_balk">
                <h1 style="font-size:0px">400电话选号中心</h1>
                <img src="../assets/images/logo.png" class="logclass" alt="400电话选号中心">
            </b-navbar-brand>
        </b-navbar>
        <!-- 搜索框 -->
        <div class="row col-12 d-none d-lg-block">
            <b-form @submit="onSubmit">
                <b-form-input v-model="array[0]" class="inputcss"  type="number" disabled></b-form-input>
                <b-form-input v-model="array[1]" class="inputcss"  type="number" disabled></b-form-input>
                <b-form-input v-model="array[2]" class="inputcss"  type="number" disabled></b-form-input>
                <b-form-input v-model="array[3]" @mousedown="focusInput(3)" :ref="'inputVal'+ 3" @input="inputhange(4)" class="inputcss"  type="number"></b-form-input>
                <b-form-input v-model="array[4]" @mousedown="focusInput(4)" :ref="'inputVal'+ 4" @input="inputhange(5)" class="inputcss"  type="number"></b-form-input>
                <b-form-input v-model="array[5]" @mousedown="focusInput(5)" :ref="'inputVal'+ 5" @input="inputhange(6)"  class="inputcss"  type="number"></b-form-input>
                <b-form-input v-model="array[6]" @mousedown="focusInput(6)" :ref="'inputVal'+ 6" @input="inputhange(7)" class="inputcss"  type="number"></b-form-input>
                <b-form-input v-model="array[7]" @mousedown="focusInput(7)" :ref="'inputVal'+ 7" @input="inputhange(8)" class="inputcss"  type="number"></b-form-input>
                <b-form-input v-model="array[8]" @mousedown="focusInput(8)" :ref="'inputVal'+ 8" @input="inputhange(9)"  class="inputcss"  type="number"></b-form-input>
                <b-form-input v-model="array[9]" @mousedown="focusInput(9)" :ref="'inputVal'+ 9" @input="inputhange(10)" class="inputcss"  type="number"></b-form-input>
                <b-button type="submit" :ref="'inputVal'+ 10" variant="primary" class="divButton">搜索</b-button>
            </b-form>
        </div>
        <div class="row col-2 kefu d-none d-lg-block">
            <p>客服中心:</p>
            <p>400-010-7788</p>
            <p>010-56708855</p>
        </div>
        <div class="divBottom d-none d-lg-block"></div>
        <div class="bodydiv">
            <div class="numdiv">
                <div class="numdivrow">
                    <p>按运营商查询：</p>
                    <template v-for="item in yysList" >
                    <a href="javascript:;" @click="getDGNum(item.yysname,'yysname','1')" :key="item.id">{{item.yysname}}</a>
                    </template>
                </div>
                <div class="numdivrow">
                    <p>按号段查询：</p>
                    <template v-for="(item,index) in hdList">
                        <a href="javascript:;" @click="getMHNum(item.substring(3)+'______','mohu','1')" :key="index">{{item}}</a>
                    </template>
                </div>
                <div class="numdivrow">
                    <p>按等级查询：</p>
                    <template v-for="item in djList">
                        <a href="javascript:;" @click="getDGNum(item.djname,'djname','1')" :key="item.id">{{item.djname}}</a>
                    </template>
                </div>
                <div class="numdivrow zhetie" :class="zhedie? '':'zhetieav'">
                    <p  @click="zhetiemet">按个性化查询：</p>
                    <div class="zhetiebtn" :class="zhedie? '':'sff'" @click="zhetiemet"></div>
                    <template v-for="item in gxhList">
                    <a href="javascript:;" @click="getDGNum(item.gxhname,'gxhname','1')" :key="item.id">{{item.gxhname}}</a>
                    </template>
                </div>
                <div class="numdivrow">
                    <p>按不含数字查询：</p>
                    <template v-for="item in noHNum">
                    <a href="javascript:;" @click="getMHNum(item,'noContain','1')" :key="item">{{item}}</a>
                    </template>
                </div>
                <div class="numdivrow">
                    <p>按尾数查询：</p>
                    <template v-for="item in hNum">
                    <a href="javascript:;" @click="getMHNum(item,'containJieWei','1')" :key="item">{{item}}</a>
                    </template>
                </div>
                <div class="numdivrow" style="color:#f60;letter-spacing:1px">
                    <p>您查询的号码为：</p>
                    {{num}} <p>{{tishi}}</p>
                </div>
                <div class="nunList">
                    <div @click.stop="yzhm(item.num,item.yysname)" class="num" v-for="item in numList" :key="item.id">
                        <p>{{item.num}}</p>
                        <i>{{item.yysname}}</i>
                        <i>{{item.djname}}</i>
                        <i>{{item.gxhname}}</i>
                        <i >{{item.tc[0].tcjg}}</i>
                    </div>
                </div>
                <div class="numfenye">
                    <!-- 分页 -->
                    <div class="ygts"><p>共：{{totoPages}} 页</p></div>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" :class="cruuPage === 1 ? 'disabled':''">
                                <a @click="syybtn(cruuPage-1)" class="page-link" href="javascript:;" aria-label="Previous" style="box-shadow:none">
                                    <span class="sr-only">上一页</span>
                                </a>
                            </li>
                                <li class="page-item active">
                                    <a class="page-link" href="javascript:;" style="box-shadow:none">{{cruuPage}}</a>
                                </li>
                            <li class="page-item" :class="totoPages == cruuPage ? 'disabled':''">
                                <a @click="xyebtn(cruuPage+1)" class="page-link" href="javascript:;" aria-label="Next" style="box-shadow:none">
                                    <span class="sr-only">下一页</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
            <!-- 分页 -->
                </div>
            </div>
            <div class="aboutdiv">
                <div class="aboutimg">
                    <img src="../assets/images/lxwm.jpg" alt="">
                </div>
                <div class="lxfsdiv">
                    <p class="gsmc"> <a href="/">全国400电话办理中心</a></p>
                    <p>北京乐泰科技有限公司</p>
                    <p>客服：400-010-7788</p>
                    <p>商务：010-56708855</p>
                    <p>网址: <a href="http://www.400haoma.cn">www.400haoma.cn</a></p>
                    <p>地址：北京市丰台区莲花池西里联华大厦516室</p>
                </div>
            </div>
        </div>
        <div class="row col-12 footdiv">
            <a href="https://beian.miit.gov.cn/" target="_back">京ICP备06038541号</a>
        </div>
        <!-- 弹出框 -->
      <b-card  header="提示" class="text-left" :class="tckflag?'':'displaynone'">
        <b-card-text class="kefuxxx">
            <img class="kefuwx" src="https://www.400haoma.cn/letai_images/wxrwm.jpg" alt="">
            <p class="colsCss" @click="colseTCK">X</p>
            <p>您选择了号码：<i style="color:#f60">{{zsNum}}--{{zsYysname}}</i></p>
            <p ref="pselect">在线客服：<a :href="kflj" target="_back"> 客服</a> (会自动识别您选择的号码)</p>
            <p ref="pselect">客服微信：13910453175</p>
            <p ref="pselect">客服电话：<a href="tel:4000107788">400-010-7788</a></p>
        </b-card-text>
      </b-card>
    </b-container>
</template>
<script>
export default {
    data(){
        return{
            // 展示号码
            num:'',
            array:['4','0','0'],
            // 是否折叠
            zhedie:true,
            // 运营商列表
            yysList:[],
            // 号段列表
            hdList:[],
            djList:[],
            gxhList:[],
            // 不含数字查询
            noHNum:['1','2','3','4','5','6','7','8','9','0'],
            // 含数字查询
            hNum:['1','2','3','4','5','6','7','8','9','0'],
            // 提示，
            tishi:'号码推荐套餐为示例套餐，具体优惠信息请联系人工客服详询！',
            // 模糊查询参数
            paramsNum:'',
            // 号码列表
            numList:[],
            // 总条数
            totoNum:0,
            // 总页数
            totoPages:'',
            // 当前页
            cruuPage:1,
            // 显示几个页码
            xsPage:5,
            // 换页需要的参数
            isMoHo:false,
            // 正在查询的参数
            cruuParams:'',
            // 正在查询的类型
            cruuAttr:'',
            // 弹出框是否显示
            tckflag:false,
            // 弹出框展示号码
            zsNum:'',
            zsYysname:'',
            // 客服连接
            kflj:'',
        }
    },
    methods:{
        // 初始化搜索框
        serchInput(){
            this.paramsNum = this.$route.params.num;
            let syNum = this.$route.params.num;
            this.array = syNum.split('');
            if(syNum.length !== 10){
                let leng = 10 - syNum.length; 
                for(let i = 0; i<leng;i++){
                    syNum += '*';
                    this.paramsNum+='_';
                }
                this.num = syNum;
                this.tishi='*代表任意数字，如果没有中意的号码可联系客服人工查询';
                this.getMHNum(this.paramsNum.substr(3,10),'mohu','1');
                return;
            }
            this.num = syNum;
            // 查单个号码
            this.getDGNum(this.num,'num','1');
        },
        // 模糊查询号码
        async getMHNum(num,attr,cruuPage){
            this.isMoHo = true;
            this.cruuParams = num;
            this.cruuAttr = attr;
            if(attr == 'noContain'){
                this.num = '不含数字：'+ num;
            }else if(attr == 'containJieWei'){
                this.num = '结尾数字为：'+ num;
            }else if(num == '6______'){
                this.num = '4006号码';
            }else if(num == '7______'){
                this.num = '4007号码';
            }else if(num == '0______'){
                this.num = '4000号码';
            }else if(num == '1______'){
                this.num = '4001号码';
            }else if(num == '8______'){
                this.num = '4008号码';
            }else if(num == '9______'){
                this.num = '4009号码';
            }
            const {data} = await this.$axios.post('/allnum/mhcx',{
                pramas:num,
                attr:attr,
                pagenum:cruuPage,
                pagesize:20
            });
            if(data.meta.status !== 200 || data.data === -2){
                this.tishi='系统内没有查询到号码,您可以联系客服人工查询!'
                return;
            }
            for(let i = 0;i< data.data.result.length;i++){
                for(let j=0;j<1;j++){
                    data.data.result[i].tc[0].tcjg = data.data.result[i].tc[0].tcjg == 0 ? '面议': data.data.result[i].tc[0].tcjg +'元/'+data.data.result[i].tc[0].tcnx+'年';
                }
            }
            this.tishi='号码推荐套餐为示例套餐，具体优惠信息请联系人工客服详询！';
            this.numList = data.data.result;
            this.totoNum = data.data.totalpage;
            this.cruuPage = data.data.cruuPage + 1;
            this.totoPages = data.data.totalPageNum;
        },
        // 查询具体号码
        async getDGNum(par,attr,cruuPage){
            this.isMoHo = false;
            this.cruuParams = par;
            this.cruuAttr = attr;
            this.zhedie = true;
            this.num = par;
            const {data} = await this.$axios.post('/allnum',{
                pramas:par,
                attr:attr,
                pagenum:cruuPage,
                pagesize:20
            });
            if(data.meta.status !== 200 || data.data === -2){
                this.tishi='系统内没有查询到号码,您可以联系客服人工查询!'
                return;
            }
            for(let i = 0;i< data.data.result.length;i++){
                for(let j=0;j<1;j++){
                    data.data.result[i].tc[0].tcjg = data.data.result[i].tc[0].tcjg == 0 ? '面议': data.data.result[i].tc[0].tcjg +'元/'+data.data.result[i].tc[0].tcnx+'年';
                }
            }
            this.tishi='号码推荐套餐为示例套餐，具体优惠信息请联系人工客服详询！';
            this.numList = data.data.result;
            this.totoNum = data.data.totalpage;
            this.cruuPage = data.data.cruuPage + 1;
            this.totoPages = data.data.totalPageNum;
        },
        // 搜索按钮点击事件
        onSubmit(){
            const hdArr = ['0','1','6','7','8','9'];
            if(!hdArr.includes(this.array[3])){
                alert(`第四位数字仅限 [${hdArr}]`);
                return;
            }
            this.array = this.array.filter(item=>item);
            this.array = this.array.splice(0,10);
            let nNum =  this.array.join('');
            this.paramsNum = nNum;
            if(nNum.length !== 10){
               let leng = 10 - nNum.length; 
                for(let i = 0; i<leng;i++){
                    nNum += '*';
                    this.paramsNum+='_';
                }
                this.num = nNum;
                this.tishi='*代表任意数字，如果没有中意的号码可联系客服人工查询';
                this.getMHNum(this.paramsNum.substr(3,10),'mohu','1');
                return;
            }
            this.num = nNum;
            // 查询单个号码
            this.getDGNum(nNum,'num','1');
        },
         // 搜索号码获取焦点
        inputhange(index){
            this.$refs[`inputVal${index}`].focus();
            this.array[index] = '';
        },
        // 选择输入时事件
        focusInput(index){
            this.array[index] = '';
            this.array = Array.from(this.array,(item)=>{
                    return item;
            });
        },
        // 个性化折叠
        zhetiemet(){
            this.zhedie = !this.zhedie;
        },
        // 获取运营商和号码段
        async getYYSList(){
            const {data} = await this.$axios.get('/yys/yyslist');
            if(data.meta.status !== 200){
                return;
            }
            this.yysList = data.data;
            Array.from(data.data, (item)=>{
                this.hdList.push(...item.hd);
            });
        },
        // 获取等级
        async getDJList(){
            const {data} = await this.$axios.get('/hmdj');
            if(data.meta.status !== 200){
                return;
            }
            this.djList = data.data;
        },
         // 获取等级
        async getGXHList(){
            const {data} = await this.$axios.get('/gxh');
            if(data.meta.status !== 200){
                return;
            }
            this.gxhList = data.data;
        },
        // 下一页
        xyebtn(par){
            this.cruuPage = par;
            if(this.isMoHo){
                // 模糊查询
                this.getMHNum(this.cruuParams,this.cruuAttr,par);
            }else{
                // 单个查询
                this.getDGNum(this.cruuParams,this.cruuAttr,par);
            }
        },
        // 上一页
        syybtn(par){
            this.cruuPage = par;
            if(this.isMoHo){
                // 模糊查询
                this.getMHNum(this.cruuParams,this.cruuAttr,par);
            }else{
                // 单个查询
                this.getDGNum(this.cruuParams,this.cruuAttr,par);
            }
        },
        // 变量num为选择的号码
        yzhm(num,yysname){
            this.tckflag = true;
            this.zsNum = num;
            this.zsYysname = yysname;
            this.kflj = 'https://v.egeel.com/cs/jump?u=1343_1336&c=other&name=&desc=选号中心选择号码：'+num;
        },
        colseTCK(){
            this.tckflag = false;
        }
    },
    created(){
        this.serchInput();
        this.getYYSList();
        this.getDJList();
        this.getGXHList();
    },
     mounted () {
      this.bodyListener = () => {
          this.tckflag = false;
      }
      document.body.addEventListener('touchmove', this.bodyListener, false)
    },
    //在实例销毁之前，从body上面移除这个方法
    beforeDestroy() {
      document.body.removeEventListener('touchmove', this.bodyListener)
    }
}
</script>
<style scoped>
p{
    font-size: 16px;
    margin: 0;
}
.kefuxxx{
    overflow: hidden;
}
.kefuxxx p{
    margin: 7px 0;
}
.kefuxxx .colsCss{
    position: absolute;
    right: 20px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
}
.kefuwx{
    position: absolute;
    right: 10px;
    width: 150px;
}
.text-left{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%,-50%);
    width: 40%;
}
.displaynone{
    display: none;
}
.topbut{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: -moz-linear-gradient(top, #fff, #eeeeee, #e5e5e5);
    background: -webkit-linear-gradient(top, #fff, #eeeeee, #e5e5e5);
}
.fhbtn {
    float: left;
}
.topbut a{
    color: #fff;
    text-align: left;
    font-size: 16px;
    border: 1px solid #76a6ec;
    padding: 6px 10px;
    border-radius: 5px;
    margin-left: 15px;
    background: -moz-linear-gradient(top, #76a6ec, #0d6efd, #76a6ec);
    background: -webkit-linear-gradient(top, #76a6ec, #0d6efd, #76a6ec);
}
.topbut p{
    display: inline-block;
}
.ygts{
    width: 100%;
    text-align: center;
    margin: 3px 0;
}
.ygts p{
    color: #999;
    font-size: 13px;
}
.numdiv .zhetie{
    height: 2.3rem;
    overflow: hidden;
}
.bodydiv .numdiv .zhetie .zhetiebtn{
    position: relative;
    top: 5px;
    width: 30px;
    float: right;
    height: 20px;
    border: 1px solid #999;
    border-radius: 5px;
}
.bodydiv .numdiv .zhetie .zhetiebtn::before{
    content: '';
    position: absolute;
    top: -5px;
    left: 5px;
    width: 18px;
    height: 18px;
    border-right: 2px solid #999;
    border-bottom: 2px solid #999;
    border-radius: 2px;
    transform: rotateZ(45deg);
}
.sff{
    transform: rotateZ(-180deg);
}
.bodydiv .numdiv .zhetieav{
    height: 100%;
}
.navbar-brand{
    margin-right: 0px;
}
.bodydiv{
    width: 100%;
    height:100%;
    margin-top: 20px;
}
.numdiv{
    float: left;
    width: 75%;
    margin-top: 5px;
}
.numdivrow{
    width: 99%;
    height: 100%;
    line-height: 30px;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
}
.numdiv .numdivrow p{
    font-size: 14px;
    color: #0d6efd;
    display: inline-block;
}
.numdiv .numdivrow a{
    display: inline-block;
    font-size: 14px;
    color: #fff;
    margin: 0 5px;
    background-color: #0d6efd;
    padding: 0 10px;
    border-radius: 5px;
}
.aboutdiv{
    float: left;
    width: 25%;
    height: 100%;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
.gsmc{
    width: 100%;
    height: 50px;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    background: -moz-linear-gradient(top, #fff, #eeeeee, #e5e5e5);
    background: -webkit-linear-gradient(top, #fff, #eeeeee, #e5e5e5);
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
}
.lxfsdiv a {
    color: #212529;
}
.lxfsdiv{
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
    
}
.lxfsdiv p{
    padding-left: 20px;
}
.kefu{
    position: absolute;
    top: 30px;
    right: 50px;
    width: 250px;
}
.kefu p{
    font-style: italic;
    letter-spacing: 3px;
    font-size: 25px;
    color: #5AA9df;
    margin: 0;
}
.kefu p:first-child{
    font-size: 18px;
}
.aboutimg{
    width: 100%;
}
.aboutimg img{
    width: 100%;
}
.navbar{
    padding: 0;
}
.logclass{
    width: 200px;
}
.divBottom{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 20px;
    border-bottom: 2px solid #5AA9df;
    box-shadow: 0px 6px 5px 0px #c3d8e7;
}
.form-control{
    display: inline-block;
    box-shadow:none;
}
.inputcss{
    width: 50px;
    margin: 0 2px;
    text-align:center;
    -moz-appearance:textfield; 
}

.inputcss::-webkit-inner-spin-button,  
.inputcss::-webkit-outer-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
}
.nunList{
    width: 100%;
}
.nunList .num{
    float: left;
    width: 23%;
    height: 115px;
    box-sizing: border-box;
    padding: 10px;
    background-color: #fafafb;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    margin:5px;
    text-align: center;
    cursor: pointer;
}
.nunList .num:hover{
    background-color: #e8e8e9;
}
.nunList .num p{
    color: #f60;
    font-size: 18px;
    letter-spacing: 1px;
    text-align: center;
}
.nunList .num i{
    display: inline-block;
    padding: 3px 5px;
    background-color: #36A9E1;
    color: #fff;
    border-radius: 2px;
    font-size: 12px;
    text-align: center;
    margin: 3px;
}
.numfenye{
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.numfenye a{
    font-size: 14px;
    color: #999;
}
.divButton{
    vertical-align:top;
    box-shadow:none;
}
.footdiv{
    width: 80%;
    bottom: 20px;
    text-align: center;
}
.footdiv a{
    display: inline-block;
    color: #bdb8b8;
    font-size: 14px;
    width: 160px;
    margin: 0 auto;
}
@media (max-width:1227px) {
        .text-left{
        width: 45%;
    }
}
@media (max-width:1093px) {
    .text-left{
        width: 50%;
    }
}
@media (max-width: 991px) {
    .text-left{
        width: 52%;
    }
    .inputDIV{
        margin-top: 100px;
    }
    .numdiv{
        width: 100%;
    }
    .bodydiv{
        margin-top: 43px;
    }
    .numdivrow{
        width: 100%;
        padding: 5px 0;
    }
    .numdiv .numdivrow a{
        margin: 0 3px;
    }
    .nunList .num{
        height: 100%;
    }
    .aboutdiv{
        width: 100%;
        border-top: 1px solid #e5e5e5;
    }
    .numdiv .zhetie{
        height: 2rem;
    }
    .footdiv{
        width: 100%;
    }
 }
 @media (max-width:955px) {
    .text-left{
        width: 55%;
    }
}
 @media (max-width:910px) {
    .text-left{
        width: 57%;
    }
}
 @media (max-width:877px) {
    .text-left{
        width: 65%;
    }
}
@media (max-width: 768px) { 
    .text-left{
        width: 70%;
    }
    .navtck{
        right: 5rem;
        top: 2rem;
    }
    .inputDIV{
        margin-top: 100px;
    }
    .nunList .num{
        width: 30%;
    }
 }
@media (max-width:720px) {
    .text-left{
        width: 75%;
    }
}
@media (max-width:670px) {
    .text-left{
        width: 85%;
    }
}
@media (max-width: 575px) {
    .text-left{
        width: 100%;
    }
    .kefuwx{
        bottom: 0px;
        width: 80px;
    }
    .navtck{
        right: 4rem;
    }
    .divButton{
        padding:0.375rem 3.75rem;
        text-align: center;
        letter-spacing: .5rem;
        margin-top: 1rem;
    }
    .nunList .num{
        width: 47%;
    }
}
@media (max-width: 530px) { 
    .navtck{
        right: 3.5rem;
    }
}@media (max-width: 414px) {
    .smcssdiv{
        width: 180px; 
        top: 165px;
    }
    .inputcss{
        width: 1.6rem;
        font-size: 0.7rem;
        padding:0.3rem 0;
    }
    .navtck{
        right: 2.5rem;
        top: 1.5rem;
    }
}
@media (max-width: 375px) { 
    .navtck{
        right: 1.8rem;
    }
    .inputcss{
        width: 1.6rem;
        font-size: 0.6rem;
        padding:0.3rem 0;
    }
}
@media (max-width: 360px) { 
    .navtck{
        right: 1.8rem;
    }
    .inputcss{
        width: 1.3rem;
        font-size: 0.6rem;
        padding:0.3rem 0;
    }
    .nunList .num{
        width: 98%;
    }
}
@media (max-width: 280px) { 
    .navtck{
        right: 1.8rem;
    }
    .inputs{
        left:53%;
    }
    .inputcss{
        width: 1.2rem;
        font-size: 0.6rem;
        padding:0.2rem 0;
    }
}
</style>