import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Pages from '../components/Pages'
Vue.use(VueRouter)

const routes = [
    { path: '/', component: Home },
    { path: '/list/:num', component: Pages, props: true }
]

const router = new VueRouter({
    routes
});

export default router