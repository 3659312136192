<template>
    <div class="kefu d-none d-lg-block"  @mouseout="shtk">
        <a href="https://v.egeel.com/cs/jump?u=1343_1336&c=other&name=&desc=xhzx" @mouseenter="tck(1)" target="_back">
            <span :class="dqIndex == 1 ? 'avt':''">在线客服</span>
        </a>
        <a href="javascript:;" @mouseenter="tck(2)">
            <span :class="dqIndex == 2 ? 'avt':''">
                <img src="https://www.400haoma.cn/letai_images/wxrwm.jpg" alt="">
            </span>
        </a>
        <a href="javascript:;" @mouseenter="tck(3)">
            <span :class="dqIndex == 3 ? 'avt':''">400-010-7788</span>
        </a>
    </div>
</template>
<script>
export default {
    data(){
        return{
            // 弹出标志
            dqIndex:'',
        }
    },
    methods:{
        tck(index){
            this.dqIndex = index;
        },
        shtk(){
            this.dqIndex = '';
        }
    }
}
</script>
<style scoped>
.kefu{
    position: fixed;
    top: 50%;
    right: 10px;
    transform: translate(0,-50%);
    width: 70px;
    border: 1px solid #6ba2f3;
    border-radius: 10px;
    text-align: center;
    background: -moz-linear-gradient(left, #6ba2f3, #014f93, #6ba2f3);
    background: -webkit-linear-gradient(left, #6ba2f3, #0d6efd, #6ba2f3);
}
.kefu a{
    position: relative;
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin: 0 0 2px 0;
    color: #fff;
    font-size: 16px;
    border-bottom: 1px solid #fff;
}
.kefu a:nth-child(1){
    background: url('https://www.400haoma.cn/letai_images/zxkefu.png') no-repeat center;
    background-size: 40px 40px;
}
.kefu a:nth-child(2){
    background: url('https://www.400haoma.cn/letai_images/rwm.png') no-repeat center;
    background-size: 40px 40px;
}
.kefu a:nth-child(3){
    background: url('https://www.400haoma.cn/letai_images/telfufu.png') no-repeat center;
    background-size: 40px 40px;
}
.kefu a:last-child{
    border-bottom: none;
}
.kefu a span{
    display: none;
    position: absolute;
    top: -1px;
    background-color: #6ba2f3;
    z-index: -999;
    animation: tcdh .8s linear forwards;
    white-space : nowrap;
}
.kefu a .avt{
    display: block;
}
.kefu a:nth-child(2) span {
    padding: 10px 10px;
}
.kefu a span img{
    width: 100%;
}
@keyframes tcdh{
    0%{
        left: 0px;
        width: 0px;
    }
    50%{
        display: block;
    }
    100%{
        left: -115px;
        width: 120px;
    }
}
</style>