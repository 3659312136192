<template>
    <b-container>
        <!-- 大屏幕显示 -->
        <b-navbar toggleable="lg" type="dark" variant="success" class="d-none d-lg-block">
                <b-nav class="col-lg">
                    <template v-for="item in smnavList">
                    <b-nav-item  :key="item.id" class="bnavitem" :href="item.url" target="_block">{{item.title}}</b-nav-item>
                    </template>
                </b-nav>
                <b-navbar-brand href="http://www.400haoma.cn" target="_balk">
                    <h1 style="font-size:0px">400电话选号中心</h1>
                    <img src="../assets/images/logo.png" class="logclass" alt="400电话选号中心">
                </b-navbar-brand>
        </b-navbar>
        <!-- 大屏幕以下显示 -->
        <b-navbar toggleable="lg" type="dark" variant="success" class="d-lg-none">
            <div class="row offset-10">
                <div class="navbz" @click.stop="smNavDisplay"><b-icon-text-indent-right></b-icon-text-indent-right></div>
            </div>
            <div class="navtck" :style="navFlag ? 'display:none':''">
                <template v-for="item in smnavList">
                    <b-nav-item  :key="item.id" class="smbnavitem" :href="item.url" target="_block">{{item.title}}</b-nav-item>
                </template>
            </div>
            <b-navbar-brand href="http://www.400haoma.cn" target="_balk">
                <h1 style="font-size:0px">400电话选号中心</h1>
                <img src="../assets/images/logo-.png" class="logclass smcssdiv" alt="400电话选号中心">
            </b-navbar-brand>
        </b-navbar>
        <div class="smbg d-lg-none"></div>
        <!-- 搜索框 -->
        <div class="row col-12 inputDIV">
            <b-form @submit="onSubmit">
                <!-- <b-form-input v-model="form.gjz"  type="number" placeholder="例：4000107788，*为通配符" autofocus>
                </b-form-input> -->
                <div class="inputs">
                    <b-form-input v-model="array[0]" class="inputcss"  type="number" disabled></b-form-input>
                    <b-form-input v-model="array[1]" class="inputcss"  type="number" disabled></b-form-input>
                    <b-form-input v-model="array[2]" class="inputcss"  type="number" disabled></b-form-input>
                    <b-form-input v-model="array[3]" @mousedown="focusInput(3)" :ref="'inputVal'+ 3" @input="inputhange(4)" class="inputcss"  type="number" autofocus></b-form-input>
                    <b-form-input v-model="array[4]" @mousedown="focusInput(4)" :ref="'inputVal'+ 4" @input="inputhange(5)" class="inputcss"  type="number"></b-form-input>
                    <b-form-input v-model="array[5]" @mousedown="focusInput(5)" :ref="'inputVal'+ 5" @input="inputhange(6)"  class="inputcss"  type="number"></b-form-input>
                    <b-form-input v-model="array[6]" @mousedown="focusInput(6)" :ref="'inputVal'+ 6" @input="inputhange(7)" class="inputcss"  type="number"></b-form-input>
                    <b-form-input v-model="array[7]" @mousedown="focusInput(7)" :ref="'inputVal'+ 7" @input="inputhange(8)" class="inputcss"  type="number"></b-form-input>
                    <b-form-input v-model="array[8]" @mousedown="focusInput(8)" :ref="'inputVal'+ 8" @input="inputhange(9)"  class="inputcss"  type="number"></b-form-input>
                    <b-form-input v-model="array[9]" @mousedown="focusInput(9)" :ref="'inputVal'+ 9" @input="inputhange(10)" class="inputcss"  type="number"></b-form-input>
                    <b-button type="submit" :ref="'inputVal'+ 10" variant="primary" class="divButton">搜索</b-button>
                </div>
            </b-form>
        </div>
        <div class="row col-12 d-lg-none suijiso">
            <a href="javascript:;" @click="sbsys">~~随机搜一下~~</a>
        </div>
        <div class="row col-10 reso offset-2 d-none d-lg-block">
            <div class="row col-12"><p>{{tishi}}</p></div>
            <template v-for="item in resoNum">
                <div class="row col-3 resoindiv" :key="item.id" @click="resoBtn(item.num)"><i>{{item.num}}</i></div>
            </template>
        </div>
        <kefu></kefu>
        <div class="row col-12 footdiv">
            <a href="https://beian.miit.gov.cn/" target="_back">京ICP备06038541号</a>
        </div>
    </b-container>
</template>
<script>
import KeFu from './KF.vue';
export default {
    data(){
        return{
            resoNum:[],
            array:['4','0','0'],
            // 上方小导航
            smnavList:[
                { id:1,title:'400电话功能',url:'https://www.400haoma.cn/gongneng.html'},
                { id:2,title:'400电话价值',url:'https://www.400haoma.cn/jiazhi.html'},
                { id:3,title:'400电话案例',url:'https://www.400haoma.cn/jiazhi.html#anli'},
                { id:4,title:'400电话选号技巧',url:'https://www.400haoma.cn/xuanhao.html'},
                { id:5,title:'关于我们',url:'https://www.400haoma.cn/about.html'},
            ],
            // 手机是否显示导航标志
            navFlag:true,
            tishi:''
            
        }
    },
    components:{
        kefu:KeFu,
    },
    methods:{
        smNavDisplay(){
            this.navFlag = !this.navFlag;
        },
        // 随便搜一下
        sbsys(){
            this.$router.push('/list/' + this.resoNum[2].num);
        },
        onSubmit(){
            const hdArr = ['0','1','6','7','8','9'];
            if(!hdArr.includes(this.array[3])){
                alert(`第四位数字仅限 [${hdArr}]`);
                return;
            }
            this.array = this.array.filter(item=>item);
            this.array = this.array.splice(0,10);
            const strNum = this.array.join('');
            this.$router.push('/list/' + strNum);
        },
        // 热搜点击
        resoBtn(num){
            this.$router.push('/list/' + num);
        },
        // 搜索号码获取焦点
        inputhange(index){
            this.$refs[`inputVal${index}`].focus();
            this.array[index] = '';
        },
        // 选择输入时事件
        focusInput(index){
            this.array[index] = '';
            this.array = Array.from(this.array,item=>{
                 return item;
            }); 
        },
        // 获取热搜号码
        async getRSNum(){
            const page = parseInt(Math.random()*(180-1+1)+1,10);
            const {data} = await this.$axios.post('/allnum',{
                pagenum:page,
                pagesize:8
            });
            if(data.meta.status !== 200 || data.data === -2){
                return;
            }
            this.tishi='热搜号码：';
            this.resoNum = data.data.result;
        }
    },
    created(){
        this.getRSNum();
    },
    mounted () {
      this.bodyListener = () => {
          this.navFlag = true;
      }
      document.body.addEventListener('touchmove', this.bodyListener, false)
    },
    //在实例销毁之前，从body上面移除这个方法
    beforeDestroy() {
      document.body.removeEventListener('touchmove', this.bodyListener)
    }
}
</script>
<style scoped>
.suijiso{
    position: relative;
    top: 7rem;
    text-align: center;
}
.suijiso a{
    font-size: 14px;
    color: #999;
}
.smbg{
    position: absolute;
    top: 0;
    left:0;
    z-index: -1;
    width: 100%;
    height: 13rem;
    background: url('../assets/images/dddd.png') no-repeat;
    background-size:100% auto;
}
.smbg{
    width: 100%;
}
.bg-success {
    background: none!important;
    height: 120px;
}
.bnavitem {
    float: left;
    font-size: 10px;
}
.nav-link{
    color: #999;
}
.logclass{
    position: absolute;
    top: 175px;
    left: 50%;
    transform: translate(-50%,0);
}
.smcssdiv{
    width: 230px; 
    top: 150px;
}
.navbz{
    font-size: 2rem;
    color: #999;
    cursor: default;
}
.navtck{
    position: absolute;
    top: .8rem;
    right: 5rem;
    z-index: 99;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    background-color: #fff;
}
.smbnavitem{
    text-align: center;
    font-size: .8rem;
    border-bottom: 1px solid #e5e5e5;
}
.smbnavitem:last-child{
    border-bottom: none;
}
.form-control{
    display: inline-block;
    box-shadow:none;
}
.inputDIV{
    margin-top: 150px;
    text-align: center;
    margin-left: 0px!important;
}
.inputs{
    float: left;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
}
.inputcss{
    width: 50px;
    margin: 0 2px;
    text-align:center;
    -moz-appearance:textfield; 
}

.inputcss::-webkit-inner-spin-button,  
.inputcss::-webkit-outer-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
} 
.divButton{
    vertical-align:top;
    box-shadow:none;
}
.footdiv{
    position: absolute;
    width: 80%;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
}
.footdiv a{
    display: inline-block;
    color: #bdb8b8;
    font-size: 14px;
    width: 160px;
    margin: 0 auto;
}
.reso{
    margin-top: 80px;
}
.reso p{
    font-size: 14px;
    margin-bottom:.3rem;
}
.resoindiv{
    cursor: pointer;
    display: inline-block;
}
.resoindiv:hover{
    color: #0d6efd;
}
.reso i{
    font-size: 14px;
    margin-bottom:.6rem;
}
@media (max-width: 1125px) { 

 }
@media (max-width: 992px) { 
    .inputDIV{
        margin-top: 100px;
    }
 }
@media (max-width: 768px) { 
    .navtck{
        right: 5rem;
        top: 2rem;
    }
    .inputDIV{
        margin-top: 100px;
    }
 }
@media (max-width: 575px) { 
    .navtck{
        right: 4rem;
    }
    .divButton{
        padding:0.375rem 3.75rem;
        text-align: center;
        letter-spacing: .5rem;
        margin-top: 1rem;
    }
}
@media (max-width: 530px) { 
    .navtck{
        right: 3.5rem;
    }
}@media (max-width: 414px) {
    .smcssdiv{
        width: 180px; 
        top: 165px;
    }
    .inputcss{
        width: 1.6rem;
        font-size: 0.7rem;
        padding:0.3rem 0;
    }
    .navtck{
        right: 2.5rem;
        top: 1.5rem;
    }
}
@media (max-width: 375px) { 
    .navtck{
        right: 2.0rem;
    }
    .inputcss{
        width: 1.6rem;
        font-size: 0.6rem;
        padding:0.3rem 0;
    }
}
@media (max-width: 320px) { 
    .navtck{
        right: 1.8rem;
    }
    .inputcss{
        width: 1.3rem;
        font-size: 0.6rem;
        padding:0.3rem 0;
    }
}
@media (max-width: 280px) { 
    .navtck{
        right: 1.8rem;
    }
    .inputs{
        left:53%;
    }
    .inputcss{
        width: 1.2rem;
        font-size: 0.6rem;
        padding:0.2rem 0;
    }
}
</style>>